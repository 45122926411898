export interface ILink {
  label: string
  url: string
  target?: boolean
}

export interface IImage {
  alt: string
  url: string
}

export interface IIconLink {
  image: IImage
  label: string
  url: string
}

export interface IFooterCard {
  title: string
  subtitle: string
  text: string
  link: ILink
  background: IImage
}

export interface IFormError {
  fieldName: string | null
  value: string
}

export enum NavLinkPosition {
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum DisplayMode {
  LIST = 'LIST',
  MOSAIC = 'MOSAIC',
}

export enum CarouselTransitionDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
